import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

import ImageBanner from "../../components/atoms/banner/imageBanner";
import Insights from "../../molecules/Insights";

// Components
import StaffingHeader from "../../molecules/staffing/staffingHeader";
import StaffingDescription from "../../molecules/staffing/staffingDescription";

import ContactForm from "../../molecules/ContactForm";
import { useStaticQuery, graphql } from "gatsby";
// import StaffingInterested from "../../molecules/staffing/staffingInterested";
import TrainingSolutions from "../../molecules/staffing/knowledgeTrainingProgram";

//images
import bannerImg from "../../images/servicesassets/knowledge-management.png";
import descImg from "../../images/servicesassets/know-management-description.png";
import trainImg from "../../images/servicesassets/validation-description.png";
import StaffingNewTimelineComponent from "../../molecules/staffing/staffingNewTimelineComponent";

const KnowledgeSolution = () => {
  const data = useStaticQuery(graphql`
    query GetKnowledgeSolutionData {
      gcms {
        servicePages(where: { pageType: KNOWLEDGE_SOLUTION }) {
          heading
          paragraph
          description
          timelineItems {
            heading
            paragraph
            listItem
            isList
          }
          technicalItems {
            heading
            paragraph
            icons {
              url
            }
          }
          sliderModals {
            tagline
            heading
            benefit
          }
          insightPages {
            shortDescription
            title
            slug
            image {
              url
            }
          }
        }
      }
    }
  `);

  return (
    <Layout isNavWhite={true} hideMap hideFAQ>
      <SEO
        canonicalLink={
          "https://www.goken-global.com/services/knowledgesolution/"
        }
        title={"Knowledge Solutions: Knowledge Solutions by Goken"}
        pageName={"Knowledge Solutions"}
        description={
          "Unlock the potential of your business with Goken's comprehensive knowledge solutions. Enhance learning and growth. Click to explore our services!"
        }
        // meta="Knowledge management, knowledge management system, knowledge management tools, training, types of knowledge management, knowledge management process, process management, training, Catia V5, Catia V5 training, 3DXperience training, engineering consulting"
        facebookTitle={
          "Goken | Top Engineering Knowledge Management Company in USA, India"
        }
        facebookDescription={
          "Goken Engineering Knowledge Management Company helps you to solve process-related challenges, and in streamline decision making. We help you to scale the product with more transperancy of product design and engineering process."
        }
        keywords={
          "“engineering services, knowledge management, solution, consulting, skill management"
        }
      />
      <StaffingHeader
        heading={data.gcms.servicePages[0].heading}
        paragraph={data.gcms.servicePages[0].paragraph}
        image={bannerImg}
        btnTxt="Get In Touch"
        url="/contactus/"
      ></StaffingHeader>
      <StaffingDescription
        headingOne="Consulting Offering"
        description={data.gcms.servicePages[0].description}
        image={descImg}
      ></StaffingDescription>

      <StaffingNewTimelineComponent
        heading="How we deliver value"
        style="yes"
        bannerService={"Knowledge Management"}
        tagline="Our holistic process leaves the client with methods documents, automation tools, well-trained associates, and thousands of hours of savings."
        items={data.gcms.servicePages[0].timelineItems}
        removeLastSection={true}
      />

      <ImageBanner
        heading="Interested in learning more about our Knowledge Management Services?"
        btnText="Get In Touch"
        link="/contactus/"
      ></ImageBanner>
      <div className="gray-back">
        <div className="section m-top-10">
          <StaffingDescription
            headingOne="Training Offering"
            description="The world of product design and manufacturing is extremely fast-paced. Development timelines are continuously shrinking as customers expect new products each year and in certain cases, every few months. Companies often need to ramp up their hiring to cater to these development demands. This creates the challenge to onboard their new associates within compressed timelines while still needing the same baseline of performance."
            noMargin={true}
            image={trainImg}
          ></StaffingDescription>
        </div>
        <StaffingDescription
          headingOne="Goken trains for higher productivity"
          description="Our decades of experience in product design and manufacturing sector has shown that effective training is key to success.  ​Goken America offers role oriented and results driven training that targets skills needed by engineers to hit the ground running by eliminating months of on-the job learning. Whether onboarding a new person, upskilling existing associates or setting up an associate development program, our method is the quickest path to productivity and efficiency gains."
          noMargin={true}
        ></StaffingDescription>
        <TrainingSolutions></TrainingSolutions>
        <ImageBanner
          heading="Learn more about Training"
          btnText="Get In Touch"
          goto="/services/training"
        ></ImageBanner>
      </div>
      <div className="p-top-3em w-90-wrapper">
        <Insights
          insights={data.gcms.servicePages[0].insightPages}
          showTitle={true}
        ></Insights>
      </div>

      {/* <ImageBanner
        heading="Learn more about Training"
        btnText="Get In Touch"
        goto = "/partnership#training"
      ></ImageBanner> */}
    </Layout>
  );
};

export default KnowledgeSolution;
